import React, { useEffect, useCallback, useState } from 'react'
import qs from 'qs'
import axios from 'axios'
import PropTypes from 'prop-types'
import { deserialize } from 'deserialize-json-api'
import { get, round, replace, map, isUndefined, join, isEmpty, isArray } from 'lodash'

import SEO from '../../atoms/Seo'
import JsonLD from '../../atoms/JsonLD'
import Navbar from '../../organisms/Navbar'
import CityPrograms from '../../organisms/CityPrograms'
import CityTopSection from '../../organisms/CityTopSection'
import { API_SALES_URL } from '../../../config'

const FranceTemplate = ({ location }) => {
  const [loading, updateLoading] = useState(true)
  const [programs, updatePrograms] = useState([])
  const [city, updateCity] = useState([])
  const [region, updateRegion] = useState([])
  const [department, updateDepartment] = useState([])
  const globalLocation = 'France'
  const [total, updateTotal] = useState(0)

  const search = qs.parse(replace(get(location, 'search'), '?', ''))
  const page = get(search, 'page')
  const [currentPage, updatePage] = useState(isUndefined(page) ? 1 : parseInt(page))

  const title = 'Programme Neuf - France'
  const description = 'Tous les appartements neufs à vendre en France du 2 au 5 pièces. Prix direct Promoteur. Terrasse, balcon, parking.'
  const linkedData = {
    '@context': 'http://schema.org',
    '@type': 'Project',
    name: 'Folhomee',
    description,
    parentOrganization: {
      '@type': 'Organization',
      name: 'Folhomee',
      location: {
        '@type': 'PostalAddress',
        streetAddress: '44 Rue du Chemin Vert',
        addressLocality: 'Paris',
        postalCode: '75011'
      }
    },
    keywords: 'Programme immobilier, Folhomee, page France, recherche immobilier, accompagnement'
  }

  const fetchLocation = useCallback(async () => {
    try {
      const context = [{
        endpoint: 'cities',
        key: 'city',
        update: updateCity
      }, {
        endpoint: 'regions',
        key: 'region',
        update: updateRegion
      }, {
        endpoint: 'departments',
        key: 'department',
        update: updateDepartment
      }]

      await Promise.all(map(context, async ({ endpoint, key, update }) => {
        const params = get(search, key, [])
        if (isEmpty(params)) {
          return
        }

        const { data } = await axios.get(`${API_SALES_URL}/api/${endpoint}`, {
          params: {
            id: params
          }
        })

        update(get(deserialize(data), 'data'))
      }))
    } catch (err) {
      console.log(err)
    }
  }, [search])

  const fetchCount = useCallback(async () => {
    try {
      const cities = get(search, 'city', [])
      const regions = get(search, 'region', [])
      const departments = get(search, 'department', [])

      const { data } = await axios.get(`${API_SALES_URL}/api/programs/count_search`, {
        params: {
          cities: isArray(cities) ? cities : [cities],
          regions: isArray(regions) ? regions : [regions],
          departments: isArray(departments) ? departments : [departments],
          lotType: get(search, 'nature'),
          minRooms: get(search, 'minRooms'),
          maxRooms: get(search, 'maxRooms'),
          maxPrice: get(search, 'budget'),
          minSurface: get(search, 'surface'),
          deliveryYear: get(search, 'deliveryYear'),
          deliveryQuarter: get(search, 'deliveryQuarter')
        }
      })

      updateTotal(get(data, 'programs', 0))
    } catch (err) {
      console.log(err)
    }
  }, [search])

  const fetchPrograms = useCallback(async () => {
    updateLoading(true)

    try {
      const { data } = await axios.get(`${API_SALES_URL}/api/programs/search`, {
        params: {
          cities: get(search, 'city', []),
          regions: get(search, 'region', []),
          departments: get(search, 'department', []),
          lotType: get(search, 'nature'),
          minRooms: get(search, 'minRooms'),
          maxRooms: get(search, 'maxRooms'),
          maxPrice: get(search, 'budget'),
          minSurface: get(search, 'surface'),
          deliveryYear: get(search, 'deliveryYear'),
          deliveryQuarter: get(search, 'deliveryQuarter'),
          'page[number]': currentPage,
          'page[size]': 15
        }
      })

      updatePrograms(get(deserialize(data), 'data'))
    } catch (err) {
      console.log(err)
    } finally {
      updateLoading(false)
    }
  }, [updateLoading, currentPage, search])

  useEffect(() => {
    fetchPrograms()
  }, [currentPage])

  useEffect(() => {
    fetchLocation()
    fetchCount()
    fetchPrograms()
  }, [location])

  return (
    <>
      <SEO
        title={title}
        location={location}
        description={description}>
        <JsonLD>
          {linkedData}
        </JsonLD>
      </SEO>
      <Navbar location={location} />
      <CityTopSection
        location={{
          type: 'all',
          city,
          region,
          department
        }}
        loading={loading}
        programs={programs} />
      <section
        id={`${globalLocation} liste des programmes neufs`}>
        <CityPrograms
          city={join(map(city, ({ name }) => name), ',')}
          page={currentPage}
          loading={loading}
          maxPage={round(total / 15)}
          programs={programs}
          updatePage={updatePage} />
      </section>
    </>
  )
}

FranceTemplate.propTypes = {
  location: PropTypes.object
}

export default FranceTemplate
